let common = {
  // api_url: "https://70d5yd0sxi.execute-api.us-east-2.amazonaws.com/dev",
  // axle_url: "https://70d5yd0sxi.execute-api.us-east-2.amazonaws.com/dev/",
api_url:"https://cgyfv95kzh.execute-api.us-east-2.amazonaws.com/nextmed",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  axle_headers: {
    Authorization: "ApiKey test_995a0dd663fc47c3899b6009511877ad",
    "Content-Type": "application/json",
  },
};
export default common;
