import React, { Suspense } from "react";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
{
  /* Next.js supports lazy loading external libraries with import() and React components with next/dynamic*/
}
const HomeInsurance = dynamic(
  () => import("../components/insuranceNotice/insuranceWl"),
  {
    suspense: true,
  }
);
const Work = dynamic(() => import("../components/howItsWork/weightlossworks"), {
  suspense: true,
});
const WeightLossMain = dynamic(() => import("../components/LandingPage/home"), {
  suspense: true,
});
const TrustPilot_New = React.lazy(() => import("../components/TrustPilot_New/TrustPilot_New"))


const Questions = dynamic(() => import("../components/questions/question"), {
  suspense: true,
});

const Banner = dynamic(() => import("../components/panelBanner/weightbanner"), {
  suspense: true,
});
const NeedWEb = dynamic(() => import("../components/whyNextmed/needWeb"), {
  suspense: true,
});
const SmallSection = dynamic(
  () => import("../components/weightloss/smallSection"),
  {
    suspense: true,
  }
);
const WeightLossCart = dynamic(
  () => import("../components/cart/weightLossCart"),
  {
    suspense: true,
  }
);
const WeightLossCartVariants = dynamic(
  () => import("../components/cart/weightLossCartVariants"),
  {
    suspense: true,
  }
);
const Before = dynamic(() => import("../components/BeforeAf/Before"), {
  suspense: true,
});
// const WeightLossCare = dynamic(
//   () => import("../components/healthcare/weightloss"),
//   {
//     suspense: true,
//   }
// );
const HealthCare = dynamic(() => import("../components/newHealthCare"), {
  suspense: true,
});
const SecondNav = dynamic(() => import("../components/secondNav/secondNav"), {
  suspense: true,
});
const Video = dynamic(
  () => import("../components/WeightLossVideo/weightlossvideo"),
  {
    suspense: true,
  }
);

const StepWork = dynamic(() => import("../components/works/work"), {
  suspense: true,
});
const HomeLogo = dynamic(() => import("../components/home-logos/homeLogo"), {
  suspense: true,
});
import { TrackPage } from "../lib/helper/friendBy";
import { plans } from "../data/weight_loss/cart.json";
import { questions } from "../data/weight_loss/lymeDiseaseQuestions.json";
import { questions2 } from "../data/weight_loss/variantQuest.json";
import { questions1 } from "../data/weight_loss/lymeDiseaseQuestions1.json";
import { compare } from "../data/weight_loss/weightCompare.json";
import { stdData } from "../data/weight_loss/lymeDiseaseStd.json";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { StdJson } from "../components/queryParam/query";
import { NextSeo } from "next-seo";
import Head from "next/head";



export default function Home(props) {

  const router = useRouter();
  const [myCart, setMyCart] = useState([]);
  const [pidName, setPidName] = useState(null);
  const [price, setPrice] = useState(0);
  const [group, setGroup] = useState(null);
  const [dki, setDki] = useState(null);
  const [gd, setGd] = useState(null);
  const [btnColor, setBtnColor] = useState("");

  const [ready, setReady] = useState(false);
  let totalPrice;

  useEffect(() => {
    router.push("/login")
  }, []);
  
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    // FreshworksWidget("hide");
    if (props.variant == "cta1") {
      setBtnColor("btnColor");
    }
    if (props.variant == "cta2") {
      setBtnColor("btnColor2")
    }
    if (props.variant == "cta3") {
      setBtnColor("btnColor2");
    }
    const generate_id = () => {
      let a = Math.round(new Date() / 1000).toString();

      let b = randomIntFromInterval(1000000, 9999999).toString();

      return b + "_" + a;
    };
    let url = window.location.href.split("?");
    let main_url = url[0];
    let event_id = generate_id();
    window &&
      window._learnq?.push([
        "track",
        "viewed product",
        {
          $event_id: event_id,
          PageUrl: main_url,
          lowest_price: ["$129"],
          testname: ["STD Basic Test"],
        },
      ]);
  }, []);

  const goToCheckout = (cart) => {
    FreshworksWidget("hide");
    let payload = {
      testImg: cart.img,
      cartName: cart.name,
      cartPrice: cart.price.toFixed(2),
      cartType: cart.type,
      month: cart.month,
      show_price: cart.show_price
    };
    myCart.push(payload);
    Cookies.set("cart", myCart);
    let getPrecent = (cart.price / 100) * 30;
    Cookies.set("discount", getPrecent);
    Cookies.set("totalPrice", cart.price.toFixed(2));
    window.location.href = "/checkout";
  };

  useEffect(() => {
    let url = window.location.href.split("?");
    let main_url = url[0];
    TrackPage(main_url)

    if (window.location.hash === "#view-tests") {
      setTimeout(() => {
        const element = document.getElementById('view-tests');
        if (element) {
          // Will scroll smoothly to the top of the section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 4000);
    }
  }, [])

  const [mrn, setMrn] = useState(false)
  const [home, setHome] = useState(null);
  const [variant, setVariant] = useState(null);
  const getRefralUser = async () => {
    let data = { mrn: props.special }
    const referal_data = await friendBuyGetCustomer(data)
    if (referal_data && referal_data.length) {
      let ref = referal_data[0]
      if (ref?.count < 5) {

        Cookies.set("special", props.special)
        setMrn(true)
      } else {
        Cookies.remove("special")
      }
    } else {
      Cookies.remove("special")
    }
  }

  useEffect(async () => {
    if (props && props.home != null) {
      if (props.home.toLowerCase() == "true") {
        Cookies.set("home", props.home);
        setHome(props.home);
      } else {
        Cookies.remove("home");
      }
    } else {
      Cookies.remove("home");
    }




    if (props && props.special) {
      getRefralUser()
    }

    if (props && props.sales_agent && props.sales_agent != undefined) {
      Cookies.set("sales_agent", props.sales_agent);
    } else {
      Cookies.remove("sales_agent");
    }
    if (props.group && props.group != undefined) {
      Cookies.set("group", "true");
      const data = await StdJson(props.group);
      if (data) {
        setGroup(data);
      } else {
        setGroup(data);
      }
    } else {
      Cookies.remove("group");
    }
    if (props && props.promo != null) {
      Cookies.set("promo", props.promo);
    } else {
      Cookies.remove("promo");
    }
    if (props && props.quest != null) {
      Cookies.set("quest", props.quest);
    } else {
      Cookies.remove("quest");
    }
    if (props && props.referrer != null) {
      Cookies.set("referrer", props.referrer);
    } else {
      Cookies.remove("referrer");
    }
    if (props.type != null || props.type2) {
      if (props.type) {
        Cookies.set("type", props.type);
      } else {
        Cookies.set("type", props.type2);
      }
    } else {
      Cookies.remove("type");
    }
    if (props.type2) {
      Cookies.set("type2", props.type2);
    } else {
      Cookies.remove("type2");
    }

    if (props && props.women != null && props.women.toLowerCase() == "true") {
      Cookies.set("women", props.women);
      document.documentElement.style.setProperty("--theme_color", "#FF799C");
    } else {
      Cookies.remove("women");
    }
    if (props && props.dki != null) {
      // FreshworksWidget("hide");
      Cookies.set("dki", props.dki);
      setDki(props.dki);
    } else {
      Cookies.remove("dki");
    }

    if (props && props.gender != null) {
      Cookies.set("gender", props.gender);
      setGd(props.gender);
    } else {
      Cookies.remove("gender");
    }
    if (props && props.shopping != null) {
      Cookies.set("shopping", props.shopping);
      setGd(props.shopping);
    } else {
      Cookies.remove("shopping");
    }
    if (props && props.fbrefer != null) {
      if (props.fbrefer === "6669667479") {
        Cookies.set("fbrefer", props.fbrefer);
      } else {
        Cookies.remove("fbrefer");
      }
    }
    Cookies.remove("reload");
    Cookies.set("path", window.location.href);

    if (props?.gclid || props?.msclkid) {
      const now = new Date();
      const days = 90;
      now.setTime(now.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + now.toUTCString();
      document.cookie = `gclid=${props?.gclid}; path=/; ${expires}`;
      document.cookie = `msclkid=${props?.msclkid}; path=/; ${expires}`;
    }
  }, []);

  useEffect(() => {
    if (props && props.variant != null) {
      Cookies.set("variant", props.variant.toLowerCase());
      setVariant(props.variant.toLowerCase());
    } else {
      Cookies.remove("variant");
    }

  }, [variant])

  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="B5Gg8jKFd4IYXqD1VdNn77LbxKbHE_9bhVEOU5uKfJI"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          name="description"
          content="Next Medical is your digital healthcare concierge. Our doctors will craft a personalized treatment plan. If eligible, medication prescribed as soon as next day."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Personalized, Doctor-Backed Testing with Treatment Near You"
        />
        {/* <meta
          property="og:description"
          content="Next Medical is your digital healthcare concierge. Our doctors will craft a personalized treatment plan. If eligible, medication prescribed as soon as next day."
        /> */}
        <meta
          property="og:image"
          content="https://www.nextmed.com/landing/imessage.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:domain"
          content="https://www.nextmed.com/"
        />
        <meta property="twitter:url" content="https://www.nextmed.com/" />
        <meta
          name="twitter:title"
          content="Personalized, Doctor-Backed Testing with Treatment Near You"
        />
        {/* <meta
          name="twitter:description"
          content="Next Medical is your digital healthcare concierge. Our doctors will craft a personalized treatment plan. If eligible, medication prescribed as soon as next day."
        /> */}
        <meta
          name="twitter:image"
          content="https://www.nextmed.com/landing/imessage.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />

        <link rel="icon" href="/favicon.ico" />

        {/* This is managed by the library react-gtm-module */}
        {/*  This script manages the deferred loading of content using Google Tag Manager (GTM). */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',4000,
{'GTM-TJTGF26':true});
          `,
          }}
        /> */}

        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
          integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU"
          crossorigin="anonymous"
        ></link>

      </Head>
      <main>

        {/* <>
          <Suspense fallback={<div></div>}>
            {props.shopping == null && (
              <SecondNav htwActive={"weight_loss"} variant={variant} />
            )}
          </Suspense>
          {variant == "ppc" ? <Fade bottom duration={1000} distance={"50px"}>
            <>

              <div className="">
                <Suspense fallback={<div></div>}>
                  <WeightLossCart
                    mrn={mrn}
                    dki={dki}
                    variant={variant}
                    type={props.type}
                    type2={props.type2}
                    id={"view-tests"}
                    insurance={props.insurance}
                    setInsuranceVal={props.setInsuranceVal}
                    shopping={props.shopping}
                    data={plans}
                    goToCheckout={goToCheckout}
                    promo={props.promo}
                    ready={ready}
                    pid={props.pid}
                    btnColor={btnColor}
                  />{" "}
                </Suspense>

                <Suspense fallback={<div></div>}>
                  <HomeLogo dki={dki} group={group} />
                </Suspense>
                <Suspense fallback={<div></div>}>
                  <NeedWEb />
                </Suspense>
                <Suspense fallback={<div></div>}>

                  <Banner
                    title={
                      <div className="OurGuarantee_main">Our Guarantee: Lose 10% of your body weight or your money back<small className="see_details_main"> (hover to see terms)</small>
                        <ul className="see_details_p">
                          <li>Members who do not achieve 10% weight loss in the first 12 months qualify for a refund of their monthly platform fees if the following conditions have been met:</li>
                          <li>Members must document their weight loss by providing dated photographs showing their weight, taken at least twice per month for the 12 months;</li>
                          <li>A physician on the NextMed platform prescribed, and the member took, Mounjaro, Ozempic, or Wegovy treatment; and
                            Members have been in good standing on monthly payments for the full 12 months
                            Note:  Only NextMed monthly platform fees are refundable.  The cost of medicines, lab tests, and doctor visits are incurred separately and are not refundable.</li>
                        </ul>
                      </div>
                    }
                  />
                </Suspense>

                <Suspense fallback={<div></div>}>
                  <Banner
                    title={
                      "HSA / FSA Eligible"
                    }
                  />
                </Suspense>
                <Suspense fallback={<div></div>}>
                  <SmallSection variant={variant} goToCheckout={goToCheckout} />
                </Suspense>
              </div>
            </>
          </Fade> : <Fade bottom duration={1000} distance={"50px"}>

            <>
              <div className={props.shopping != null ? "gapping-div " : ""}>
                {props.competitor ? (
                  <Suspense fallback={<div></div>}>
                    <HealthCare
                      competitor={props.competitor}
                      variant={variant}
                    /></Suspense>
                ) : (
                  <Suspense fallback={<div></div>}>
                    <div className="carasual_main12">
                      <WeightLossMain
                        mrn={mrn}
                        gender={gd}
                        shopping={props.shopping}
                        goToCheckout={goToCheckout}
                        home={home}
                        quiz={props.quiz}
                        variant={variant}
                        pidName={pidName}
                        dki={dki}
                        group={group}
                        data={stdData}
                        type={props.type}
                        type2={props.type2}
                      />
                    </div>
                  </Suspense>)}
              </div>
              <Suspense fallback={<div></div>}>
                <HomeLogo dki={dki} group={group} />
              </Suspense>
              {variant == "newhow" ?
                <Suspense fallback={<div></div>}>

                  <StepWork shopping={props.shopping} />
                </Suspense > :
                <Suspense fallback={<div></div>}>
                  <Work shopping={props.shopping} />
                </Suspense >}

              <div className="">
                <Suspense fallback={<div></div>}>

                  <Banner
                    title={
                      <div className="OurGuarantee_main">Our Guarantee: Lose 10% of your body weight or your money back<small className="see_details_main"> (hover to see terms)</small>
                        <ul className="see_details_p">
                          <li>Members who do not achieve 10% weight loss in the first 12 months qualify for a refund of their monthly platform fees if the following conditions have been met:</li>
                          <li>Members must document their weight loss by providing dated photographs showing their weight, taken at least twice per month for the 12 months;</li>
                          <li>A physician on the NextMed platform prescribed, and the member took, Mounjaro, Ozempic, or Wegovy treatment; and
                            Members have been in good standing on monthly payments for the full 12 months
                            Note:  Only NextMed monthly platform fees are refundable.  The cost of medicines, lab tests, and doctor visits are incurred separately and are not refundable.</li>
                        </ul>
                      </div>
                    }
                  />
                </Suspense>

                <Suspense fallback={<div></div>}>
                  <HomeInsurance dki={props.dki} group={props.group} />
                </Suspense>
                <Suspense fallback={<div></div>}>
                  <NeedWEb />
                </Suspense>
                {variant !== 'simple' && (
                  <>

                    <Suspense fallback={<div></div>}>
                      <Banner title={"HSA / FSA Eligible"} />
                    </Suspense>


                  </>
                )}
                <Suspense fallback={<div></div>}>

                  <Questions
                    questions={
                      props.shopping == null
                        ? variant == "cta2"
                          ? questions2
                          : questions
                        : questions1
                    }
                    dki={props.dki}
                    type={props.type}
                  />
                </Suspense>
              </div>
            </>
          </Fade>}


        </> */}

      </main>
    </>
  );
}
export async function getServerSideProps(context) {
  const data = context.query;
  return {
    props: {
      home: data.home ? data.home : null,
      group: data.group ? data.group : null,
      quest: data.quest ? data.quest : null,
      queryparams: data,
      women: data.women ? data.women : null,
      dki: data.dki ? data.dki : null,
      promo_type: data.promo ? data.promo : null,
      gender: data.gender ? data.gender : null,
      promo: data.promo ? data.promo : null,
      type: data.type ? data.type : null,
      type2: data.type2 ? data.type2 : null,
      variant: data.variant ? data.variant : null,
      competitor: data.competitor ? data.competitor : null,
      shopping: data.shopping ? data.shopping : null,
      referrer: data.referrer ? data.referrer : null,
      fbrefer: data.fbrefer ? data.fbrefer : null,
      special: data.special ? data.special : null,
      pid: null,
      quiz: data.quiz ? data.quiz : null,
      sales_agent: data.sales_agent ? data.sales_agent : null,
      gclid: data.gclid ? data.gclid : null,
      msclkid: data.msclkid ? data.msclkid : null,
    }, // will be passed to the page component as props
  };
}


